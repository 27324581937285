<template>
  <div id="job-page">
    <!-- Start Page Title Area -->
    <div class="page-title-area bg-5 padding-50-pr bg-jobs" data-aos="fade-up" data-aos-delay="0" data-aos-duration="1000">
      <div class="container">
        <div class="page-title-content">
          <h2>Jobs & Internships</h2>
          <p class="text-white">Join the PeaceJam Team</p>
        </div>
      </div>
    </div>

    <!-- End Page Title Area -->

    <!-- Start Faq Area -->
    <div class="faq-section pt-50 ptb-100" data-aos="fade-up" data-aos-delay="300" data-aos-duration="1000">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="loading" v-if="job_data === undefined && !job_error">
              <Loading />
            </div>
            <div class="faq-accordion" v-if="job_data">
              <ul class="accordion">
                <li
                  class="accordion-item"
                  v-for="(item, index) in job_data.data"
                  :key="index"
                  :class="[
                    'accordion accordion--primary',
                    { 'is-open': isOpen.includes(index) },
                  ]"
                >
                  <a
                    :class="[
                      'accordion-title',
                      { 'active': isOpen.includes(index) },
                    ]"
                    @click="toggleAccordion(index)"
                  >
                    <i class="fa fa-plus"></i>
                    {{ item.title }}
                  </a>

                  <div class="accordion-content show accordion-body">
                    <p v-html="item.description" class="white-space-pre-wrap"></p>
                    <!-- <p v-if="item.file_upload">
                      <a target="_blank" v-bind:href="''+item.url+''">Learn More</a>
                    </p> -->
                    <div class="events-btn text-right">
                      <span v-if="item.file_upload">
                        <JobFileUpload :file="item.file_upload" />
                      </span>
                      <span v-if="item.url">
                        <a class="events-btn-two link" target="_blank" rel="noreferrer" :href="item.url">Apply Now</a>
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="section-title section-title-bottom mainText">
          <h2>An Equal Opportunity Employer</h2>
          <p>
            PeaceJam is proud to be an Equal Opportunity Employer. PeaceJam's 14
            Nobel Peace Laureates have dedicated their lives to human rights,
            justice, and equity, and, as an organization, we are committed to
            creating a safe, inclusive, and uplifting environment for all
            employees and others with whom we work.
          </p>
          <p>
            We do not discriminate on the basis of race, color, ancestry,
            national origin, religion, mental or physical disability, medical
            condition, sex, sexual orientation, gender, age, marital status,
            political affiliation, military or veteran status, or other
            characteristics protected by state or federal law or local
            ordinance.
          </p>
        </div>

      </div>
    </div>
    <!-- End Faq Area -->

    <section class="faq-contact-area ptb-100" ref="volunteer">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="contact-wrap">
              <div class="contact-form">
                <div class="section-title">
                  <h2>Volunteer Registration</h2>
                  <p>The PeaceJam Foundation and our affiliates around the globe have exciting volunteer opportunities around the globe. Complete the form below if you are interested in volunteering with us!</p>
                </div>

                <form
                  id="contactForm"
                  :action="formspreeURL"
                  method="POST"
                >
                  <div class="row">
                    <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                        <input
                                type="text"
                                v-model="volunteer.firstName"
                                id="firstName"
                                name="First Name"
                                class="form-control"
                                placeholder="First Name"
                                :class="{ 'is-invalid': submitted && $v.volunteer.firstName.$error }"
                        />
                        <div v-if="submitted && !$v.volunteer.firstName.required" class="help-block with-errors">First
                          Name is required
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                        <input
                                type="text"
                                v-model="volunteer.lastName"
                                id="lastName"
                                name="Last Name"
                                class="form-control"
                                placeholder="Last Name"
                                :class="{ 'is-invalid': submitted && $v.volunteer.lastName.$error }"
                        />
                        <div v-if="submitted && !$v.volunteer.lastName.required" class="help-block with-errors">Last Name
                          is required
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                        <input
                                type="text"
                                v-model="volunteer.email"
                                id="email"
                                name="Email"
                                class="form-control"
                                placeholder="Email"
                                :class="{ 'is-invalid': submitted && $v.volunteer.email.$error }"
                        />
                        <div v-if="submitted && $v.volunteer.email.$error" class="help-block with-errors">
                          <span v-if="!$v.volunteer.email.required">Email is required</span>
                          <span v-if="!$v.volunteer.email.email">Email is invalid</span>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                      <div class="form-group">
                        <input
                                type="text"
                                v-model="volunteer.location"
                                id="location"
                                name="Location"
                                class="form-control"
                                placeholder="Location (State, Country)"
                                :class="{ 'is-invalid': submitted && $v.volunteer.location.$error }"
                        />
                        <div v-if="submitted && !$v.volunteer.location.required" class="help-block with-errors">Location
                          is required
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex flex-column align-items-start text-left volunteer">
                      <div class="form-group">
                        <label class="mb-3">Please indicate which areas you are most interested in volunteering:</label>
                        <input type="text" style="display: none" :value="volunteer.areas_to_volunteer.join(', ')" name="Volunteering"/>

                        <ul>
                          <li>
                            <input id="c1" value="Club" type="checkbox" v-model="volunteer.areas_to_volunteer">
                            <label for="c1">Start a Local PeaceJam Club</label>
                          </li>
                          <li>
                            <input id="c2" value="Social Media" type="checkbox" v-model="volunteer.areas_to_volunteer">
                            <label for="c2">Help with Social Media</label>
                          </li>
                          <li>
                            <input id="c3" value="Design" type="checkbox" v-model="volunteer.areas_to_volunteer">
                            <label for="c3">Help with Graphic Design</label>
                          </li>
                          <li>
                            <input id="c4" value="Events" type="checkbox" v-model="volunteer.areas_to_volunteer">
                            <label for="c4">Event Planning</label>
                          </li>
                          <li>
                            <input id="c5" value="Campaign" type="checkbox" v-model="volunteer.areas_to_volunteer">
                            <label for="c5">BillionActs Campaign</label>
                          </li>
                        </ul>

                        <div v-if="submitted && !$v.volunteer.areas_to_volunteer.required" class="help-block with-errors">
                          Volunteering type is required
                        </div>
                      </div>
                    </div>

                    <div class="col-12 form-group">
                      <div class="help-block with-errors" v-if="areasVolunteerError">Areas to volunteer is required</div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                      <button class="default-btn page-btn" @click.prevent="handleSubmit">
                        Submit
                      </button>
                      <button id="msgSubmit" type="submit" class="h3 text-center hidden"></button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import api from "../services/api";
import Loading from "../components/Loading";
import {required, email} from "vuelidate/lib/validators";
import useSWRV from "swrv";
import JobFileUpload from "../components/JobFileUpload";

export default {
  name: "Jobs",
  data() {
    return {
      isOpen: [],
      volunteer: {
        firstName: "",
        lastName: "",
        email: "",
        location: "",
        areas_to_volunteer: [],
      },
      not_A_Robot: false,
      submitted: false,
      areasVolunteerError: false,
      formspreeURL: 'https://flexibleforms.co/f/LRCsPgy7aMm72fhqMeWP'
    };
  },
  setup() {
    const {
      data: job_data,
      error: job_error,
      isValidating: job_isValidating
    } = useSWRV(() => `items/jobs?status=published`, api.fetcher);
    return {
      job_data,
      job_error,
      job_isValidating,
    }
  },
  validations: {
    volunteer: {
      firstName: {required},
      lastName: {required},
      email: {required, email},
      location: {required},
      areas_to_volunteer: {required},
    }
  },
  async mounted() {
    window.scrollTo(0, 0);
    if (this.$route.query.go && this.$route.query.go === 'volunteer') {
      this.$refs.volunteer.scrollIntoView({behavior: "smooth", block: "start"});
    }
  },
  methods: {
    toggleAccordion(index) {
      if (this.isOpen.includes(index)) {
        this.isOpen = this.isOpen.filter((i) => i !== index);
        return;
      }
      this.isOpen.push(index);
    },
    handleSubmit() {
      this.submitted = true;
      this.$v.$touch();

      // stop here if form is invalid
      if (this.$v.$invalid) {
        return;
      }

      document.getElementById('msgSubmit').click();
    }
  },
  components: {
    JobFileUpload,
    Loading
  },
};
</script>

<style scoped lang="scss">
.faq-section {
  background-image: url("../assets/img/jobs-bg.jpg");
}
.faq-section .row {
  background-color: #f3f2fe;
}
.accordion:not(.is-open) > .accordion-body {
  display: none;
}

.accordion > .accordion-body {
  padding: 20px 20px 20px 16px;
}

.accordion__text-block {
  display: block;
}
.bg-job {
  background-image: url("../assets/img/jobs/job-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px 18% !important;
}
.bg-job:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.15);
}
.bg-job > .container {
  position: relative;
  z-index: 1;
}

.section-title.mainText p {
  max-width: unset;
  margin: 20px 0px;
  text-align: start;
}

.link {
    font-size: 15px;
    color: #ed272a;
    font-weight: bold;
    display: inline-block;
    margin-right: 30px;
}
.form-group {
  position: relative;

  .with-errors {
    position: absolute;
    color: #dc3545;
    font-size: 13px;
  }
}
.volunteer {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: max-content;
  }
}
.input-group_left {
  position: fixed;
  z-index: -100;
  top: -100000px;
  left: -100000px;
}
</style>
